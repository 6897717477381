import React, { useState, useEffect } from 'react';
import './App.css';
import discordLogo from './assets/discord.png';
import riotLogo from './assets/riot.png';
import valolyticsLogo from './assets/valolytics.png';
import { SiValorant } from "react-icons/si";
import { FaDiscord } from "react-icons/fa";
import { AiOutlineDisconnect } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { MdError } from "react-icons/md";
import { IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { GoLinkExternal } from "react-icons/go";

const App: React.FC = () => {
  const loginWithDiscord = async () => {
    window.location.href = `https://api.valolytics.gg/api/auth/discord`;
  };

  const disconnectDiscord = async () => {
    try {
      const res = await axios.post('https://staging-api.valolytics.gg/api/auth/discord/disconnect', {},
      {
        withCredentials: true
      });
      if (res.status === 204) {
        window.location.reload();
      } else {
        setCookie('error', 'An error occurred during disconnecting Discord. Please contact the support team.', { path: '/', expires: new Date(Date.now() + 1000 * 5) });
      }
    } catch (error) {
      setCookie('error', 'An error occurred during disconnecting Discord. Please contact the support team.', { path: '/', expires: new Date(Date.now() + 1000 * 5) });
      console.error('An error occurred during disconnecting Discord:', error);
      window.location.reload();
    }
  }

  const loginWithRiot = () => {
    setRiotLoading(true);
    window.location.href = 'https://api.valolytics.gg/api/auth/riot';
  };

  const handleLogout = async () => {
    try {
      await axios.get('https://staging-api.valolytics.gg/api/users/logout', {
        withCredentials: true
      });
      removeCookie('session');
      window.location.reload();
    } catch (error) {
      console.error('An error occurred during logout:', error);
      window.location.reload();
    }
  };

  const [cookies, setCookie, removeCookie] = useCookies(['session', 'error']);
  const [userData, setUserData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [riotLoading, setRiotLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://staging-api.valolytics.gg/api/users/me', {
          withCredentials: true
        });
        setUserData(response.data);
      } catch (err: any) { } 
      finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="error">
        {cookies.error ? (
            <>
              <div className="error-header">
                <MdError size="25" color="white" />
                <h2>Error</h2>
              </div>
              <p className="error-message">{cookies.error}</p>
            </>
        ) : <></>}
      </div>
      <div className="container">
        {userData ? (
          <div className="logout">
            <Tooltip title="Logout">
              <IconButton>
                <FiLogOut size="20" className="pointer-on-hover" color="#e0e0e0" onClick={handleLogout} />
              </IconButton>
            </Tooltip>
          </div>
        ) : <></>}
        <img className="pointer-on-hover" src={valolyticsLogo} alt="Valolytics Logo" onClick={() => window.location.href = '/'} />
        {userData ? (
          <div className="user-info">
            {userData.puuid ? (
              <div className="riot-information">
                <img src={`https://media.valorant-api.com/playercards/${userData.playerCard ?? '9fb348bc-41a0-91ad-8a3e-818035c4e561'}/displayicon.png`} alt="Riot Avatar" />
                <span className="riot-gameName">{userData.gameName}</span><span className="riot-tagLine">#{userData.tagLine}</span>
                <SiValorant color="#FF4655" size="20px" style={{marginLeft: '5px'}} />
                <Tooltip title="View profile">
                  <IconButton href={`https://astro.valolytics.gg/players/${userData.puuid}`} target="_blank">
                    <GoLinkExternal size="20px" color="#e0e0e0" />
                  </IconButton>
                </Tooltip>
              </div>
            ) : (<></>)}
            <h3>Connections</h3>
            {userData.socials.discord !== undefined ? (
              <div className="discord-information">
              <img src={`https://cdn.discordapp.com/avatars/${userData.socials.discord?.id}/${userData.socials.discord?.avatar}.png`} alt="Discord Avatar" />
              <span className="discord-username">{userData.socials.discord?.username}</span>
              <FaDiscord color="#5865F2" size="20px" style={{marginLeft: '5px'}} />
              <Tooltip title="Disconnect">
                <IconButton>
                  <AiOutlineDisconnect className="pointer-on-hover" color="#e0e0e0" fontWeight={20} size="20px" style={{marginLeft: '5px'}} onClick={disconnectDiscord} />
                </IconButton>
              </Tooltip>
            </div>
            ) : (
              <button className="discord" onClick={loginWithDiscord}>
                <img src={discordLogo} alt="Discord Logo" /> Connect Discord
              </button>
            )}
          </div>
        ) : (
          <button className="riot" onClick={loginWithRiot}>
            <img src={riotLogo} alt="Riot Logo" /> {riotLoading ? (
              <div className="loading-dots">
                <span>⋅</span><span>⋅</span><span>⋅</span>
              </div>
            ) : (<>Connect Riot Games</>)}
          </button>
        )}
      </div>
    </>
  );
};

export default App;
